export function SliderInit() {
    const genericSliderEls = document.querySelectorAll('.js-slider')
    const mobileSliderEls = document.querySelectorAll('.js-mobile-slider')

    if (genericSliderEls.length || mobileSliderEls.length) {
        import('./slider/index')
            .then((m) => m.Slider)
            .then((Slider) => {
                genericSlider(Slider, genericSliderEls)
                mobileSlider(Slider, mobileSliderEls)
            })
    }
}

function mobileSlider(Slider, els) {
    if (!els || !els.length) {
        return
    }

    const sliders = Array.from(els)

    sliders.forEach((el) => {
        Slider(el, {
            options: {
                dots: true,
                autoplay: true,
                isInfinite: true,
                horizontalSlide: true,
                dotFormat: (x) => `<span class="u-visually-hidden">slide ${x}</span>`,
                responsive: {
                    '768px': {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        isInfinite: false,
                        autoplay: false,
                        dots: false
                    }
                }
            }
        })
    })
}

function genericSlider(Slider, els) {
    if (!els || !els.length) {
        return
    }
    const sliders = Array.from(els)
    window.sliders = sliders.map((el) => {
        let { dots, slidesToShow, slidesToScroll, autoplay, arrows, isInfinite, mobileDisable } =
            el.dataset
        dots = dots === 'true'
        autoplay = autoplay === 'true'
        arrows = arrows === 'true'
        mobileDisable = mobileDisable === 'true'
        isInfinite = isInfinite !== 'false'
        slidesToShow = +slidesToShow || 1
        slidesToScroll = +slidesToScroll || 1
        let responsive = {}

        if (slidesToShow === 4) {
            slidesToShow = 2
            slidesToScroll = 2

            responsive = {
                '1024px': {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            }
        }

        if (slidesToShow === 6) {
            slidesToShow = 2
            slidesToScroll = 2

            responsive = {
                '768px': {
                    slidesToShow: 6,
                    slidesToScroll: 6
                }
            }
        }

        if (slidesToShow === 3) {
            slidesToShow = 1
            slidesToScroll = 1

            responsive = {
                '768px': {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        }

        if (mobileDisable) {
            if (!responsive['768px']) {
                responsive['768px'] = {}
            }

            responsive['768px'].disabled = false
        }

        return Slider(el, {
            options: {
                slidesToShow,
                slidesToScroll,
                horizontalSlide: true,
                arrows,
                autoplay,
                referenceArrows: true,
                isInfinite,
                dots,
                referenceDotContainer: true,
                disabled: mobileDisable,
                dotFormat: (x) => `<span class="u-visually-hidden">slide ${x}</span>`,
                responsive
            }
        })
    })
}
