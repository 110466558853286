import {
    initJump,
    // jump,
    // LazyLoad,
    SmoothScroll,
    // post,
    // postFormData,
    // youtubeVideo,
    // createUniqueHash,
    // addIntersectionItem,
    // setCookie,
    // getCookie,
    // isScrollingDown,
    waitAnimationFrame,
    // easeInOutQuad,
    // easeOutQuad,
    // animationEnd,
    // transitionEnd,
    ProgressiveHover,
    setViewUnits
} from './02-tools'
import { ScrollAnim, SetupVideos, SetupAutoPlayVideos, ReadMore, PageList } from './06-utilities'
import {
    Selects, // dynamic import
    FormInit, // dynamic import
    // HoverLabelInit, // dynamic import
    AccordionsInit, // dynamic import
    // modalController,
    // Modals, // dynamic import
    SliderInit, // dynamic import,
    // SetupTabs, // dynamic import
    // HoverItems,
    Header,
    gmap
} from './07-components'

// import { listen } from 'quicklink'
import lazySizes from 'lazysizes'
import 'wicg-inert'

function init() {
    document.body.classList.remove('preload')
    document.body.classList.add('loaded') // trigger css to know js has loaded
    setViewUnits()

    // wait for style update from .loaded class
    waitAnimationFrame(() => {
        AccordionsInit()
        initJump()
        SliderInit()
        // Modals()
        FormInit()
        Header()
        // SetupTabs()
        Selects()
        PageList((el) => {
            ProgressiveHover(el)
        })
        gmap()
        SetupVideos()
        SetupAutoPlayVideos()
        ScrollAnim(['.u-scroll-anim'])
        SmoothScroll()
        ReadMore()
        // HoverItems()
        ProgressiveHover()

        // if (!window.isLoggedIn) {
        //     listen()
        // }
        if (typeof lazySizes === 'function') {
            lazySizes()
        }
    })
}

if (document.readyState !== 'loading') {
    init()
} else {
    document.addEventListener('DOMContentLoaded', init)
}
