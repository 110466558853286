export function AccordionsInit(context = document) {
    const accordionListEls = context.querySelectorAll('.js-accordion-list')

    if (accordionListEls.length) {
        import('./accordion')
            .then((m) => m.Accordions)
            .then((Accordions) => {
                Accordions(accordionListEls)
            })
    }
}
