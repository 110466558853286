import { transitionEnd } from '../../02-tools'

let header
let nav
let navBtn
let subNav

export function Header() {
    header = document.querySelector('.js-header')
    if (!header) {
        return
    }

    navBtn = header.querySelector('.js-nav-btn')
    nav = header.querySelector('.js-nav')
    subNav = header.querySelectorAll('.js-sub-nav')

    subNav = Array.from(subNav)
    subNav = subNav.reduce((acc, el) => {
        let button = el.querySelector('.js-sub-nav-btn')
        let list = el.querySelector('.js-sub-nav-list')
        let label = button.querySelector('span').innerText

        acc[label] = {
            el,
            button,
            list,
            isOpen: false,
            animationFrame: null
        }

        return acc
    }, {})

    setupEvents()
}

function setupEvents() {
    navBtn.addEventListener('click', toggleNav)

    nav.addEventListener('click', (evt) => {
        if (!evt.target.classList.contains('js-sub-nav-btn')) {
            return
        }

        let subNavLabel = evt.target.querySelector('span').innerText

        toggleSubNav(subNavLabel)
    })

    transitionEnd(nav).subscribe((evt) => {
        if (!evt.target.classList.contains('js-sub-nav-list')) {
            return
        }

        let label = evt.target
            .closest('.js-sub-nav')
            .querySelector('.js-sub-nav-btn span').innerText

        transitionEndSubNav(label)
    })
}

function toggleNav() {
    if (nav.dataset.open) {
        closeNav()
    } else {
        openNav()
    }
}

function closeNav() {
    nav.removeAttribute('data-open')
    document.body.removeAttribute('data-lock')
}

function openNav() {
    nav.dataset.open = true
    document.body.dataset.lock = true
}

function toggleSubNav(label) {
    if (!subNav[label]) {
        console.warn(`no subnav ${label}`)
        return
    }

    if (subNav[label].isOpen) {
        closeSubNav(label)
    } else {
        openSubNav(label)
    }
}

function openSubNav(label) {
    if (!subNav[label]) {
        console.warn(`no subnav ${label}`)
        return
    }

    if (subNav[label].animationFrame) {
        cancelAnimationFrame(subNav[label].animationFrame)
    }

    subNav[label].list.style.height = Math.ceil(subNav[label].list.scrollHeight) + 'px'
    subNav[label].el.dataset.open = true
    subNav[label].isOpen = true

    console.log('open subnav ' + label)
}

function closeSubNav(label) {
    if (!subNav[label]) {
        console.warn(`no subnav ${label}`)
        return
    }

    if (subNav[label].animationFrame) {
        cancelAnimationFrame(subNav[label].animationFrame)
    }

    subNav[label].list.style.height = Math.ceil(subNav[label].list.scrollHeight) + 'px'

    subNav[label].animationFrame = requestAnimationFrame(() => {
        subNav[label].el.removeAttribute('data-open')
        subNav[label].list.style.height = 0
        subNav[label].animationFrame = null
    })

    subNav[label].isOpen = false

    console.log('close subnav ' + label)
}

function transitionEndSubNav(label) {
    if (!subNav[label]) {
        console.warn(`no subnav ${label}`)
        return
    }

    if (subNav[label].isOpen) {
        subNav[label].list.style.height = 'auto'
    }
}
