export { Selects } from './js/selects'

export function FormInit() {
    const formEls = document.querySelectorAll('.js-form')
    const forms = Array.from(formEls)
    if (forms.length) {
        import('./js/controller')
            .then((m) => m.FormController)
            .then((FormController) => {
                forms.forEach((el) => {
                    FormController(el)
                    DatePicker(el)
                    // HoverLabelInit(el)
                })
            })
    }
}

// export function HoverLabelInit(context = document) {
//     let inputEls = context.querySelectorAll('.u-form-label')
//     if (inputEls.length) {
//         import('./hover-label')
//             .then((m) => m.HoverLabel)
//             .then((HoverLabel) => {
//                 HoverLabel(inputEls)
//             })
//     }
// }

export function DatePicker(context = document) {
    let datePickers = context.querySelectorAll('.js-date')
    if (datePickers.length) {
        const DATE_FORMAT = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/
        Array.from(datePickers).forEach((el) => {
            let newEl = document.createElement('duet-date-picker')
            newEl.setAttribute('identifier', el.name)
            newEl.setAttribute('name', el.name)
            newEl.setAttribute('value', el.value)

            newEl.dateAdapter = {
                parse(value = '', createDate) {
                    const matches = value.match(DATE_FORMAT)

                    if (matches) {
                        return createDate(matches[3], matches[2], matches[1])
                    }
                },
                format(date) {
                    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
                }
            }

            newEl.localization = {
                placeholder: 'DD/MM/YYYY',
                buttonLabel: 'Choose date',
                selectedDateMessage: 'Selected date is',
                prevMonthLabel: 'Previous month',
                nextMonthLabel: 'Next month',
                monthSelectLabel: 'Month',
                yearSelectLabel: 'Year',
                closeLabel: 'Close window',
                keyboardInstruction: 'You can use arrow keys to navigate dates',
                calendarHeading: 'Choose a date',
                dayNames: [
                    'Sunday',
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday'
                ],
                monthNames: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ],
                monthNamesShort: [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec'
                ]
            }

            el.parentElement.replaceChild(newEl, el)
        })

        import('@duetds/date-picker/custom-element')
            .then((m) => m.defineCustomElements)
            .then((defineCustomElements) => {
                defineCustomElements(window)
            })
    }
}
