import { addIntersectionItem } from '../../02-tools'

let loaded = false

export function gmap(selector = '.js-map', context = document) {
    const mapElement = context.querySelector(selector)
    if (!mapElement) {
        return
    }

    if (typeof window.google !== 'undefined') {
        initMap(mapElement)
        return
    }

    addIntersectionItem(mapElement, ({ el }, entry) => {
        if (loaded || !entry.isIntersecting) {
            return
        }

        loaded = true
        var mapScript = document.createElement('script')
        mapScript.onload = () => {
            initMap(el)
        }
        mapScript.src = '//maps.googleapis.com/maps/api/js?key=' + window.GOOGLE_MAP_KEY

        const nonceTag = document.querySelector('meta[name="csp-nonce"]')

        if (nonceTag) {
            mapScript.setAttribute('nonce', nonceTag.content)
        }

        document.body.appendChild(mapScript)
    })
}

function initMap(selector) {
    if (typeof window.google === 'undefined') {
        setTimeout(() => {
            initMap(selector)
        }, 1250)
        return
    }

    const mapElements = document.querySelector('.js-map')
    if (!mapElements) {
        return
    }

    const tmpLink = mapElements.parentElement.querySelector('.js-map-tmp-link')

    let { lat, lng, zoom } = mapElements.dataset
    zoom = +zoom || 17
    const { Map, Marker } = window.google.maps
    const latLng = {
        lat: +lat || 51.235124,
        lng: +lng || -1.118506
    }
    let center = {
        lat: latLng.lat,
        lng: latLng.lng
    }
    if (window.innerWidth <= 786) {
        center = latLng
    }

    const customIcon = {
        url: '/application/themes/the_escape/assets/images/logo.svg',
        size: new google.maps.Size(50, 50),
        scaledSize: new google.maps.Size(50, 50),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(25, 25)
    }

    const map = new Map(mapElements, {
        zoom,
        center: center,
        scrollwheel: false,
        gestureHandling: 'cooperative'
    })

    map.addListener('renderingtype_changed', () => {
        if (map.renderingType !== 'UNINITIALIZED' && tmpLink) {
            tmpLink.remove()
        }
    })

    new Marker({
        position: center,
        map,
        title: 'Benyon Estate',
        icon: customIcon
    })
}
window.initMap = initMap
