import { addIntersectionItem } from '../../02-tools'

export function ScrollAnim(selectors) {
    setupScrollAnimation(selectors)
}

function setupScrollAnimation(selectors = []) {
    selectors.push('.js-scroll-anim')
    const scrollAnimationElements = document.querySelectorAll(selectors.join(', '))
    const scrollAnimations = Array.from(scrollAnimationElements)

    const blockquoteAnimationElements = document.querySelectorAll('.typography blockquote')
    const blockquoteAnimations = Array.from(blockquoteAnimationElements)

    scrollAnimations.concat(blockquoteAnimations).forEach((el) => {
        const options = {
            isActive: false
        }
        let offset = 15
        if (el.dataset.scrollAnimOffset) {
            offset = el.dataset.scrollAnimOffset.replace(/[^0-9]/, '')
        }
        addIntersectionItem(el, handleIntersection, options, `0px 0px -${offset}% 0px`)
    })
}

function handleIntersection(intersectionObj, entry) {
    const { el, options } = intersectionObj

    if (!entry.isIntersecting || entry.intersectionRatio < 0.2 || options.isActive) {
        return
    }

    el.dataset.scrollAnimActive = true
    options.isActive = true
}
